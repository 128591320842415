import { CookieBar, CookieBarButtons, CookieBarButton, Container, Col, LinkList, LinkListItem, Form, Label, Row, Input, Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import logo from '../../img/regione-sicilia-logo.svg';
import { useState } from 'react';
import { PrivacyPolicyModal } from '../custom/PrivacyPolicyModal';
import { CookiePolicyModal } from '../custom/CookiePolicyModal';
import { CookiesBanner } from '../custom/CookiesBanner';
import { TerminiECondizioniModal } from '../custom/TerminiECondizioniModal';
import '../../App.css';


function CustomFooter() {
    const [openDialogCookie, setOpenDialogCookie] = useState(false);
    const [openDialogPrivacyPolicy, setOpenDialogPrivacyPolicy] = useState(false);
    const [openDialogTerminiECondizioni, setOpenDialogTerminiECondizioni] = useState(false);

    return (
        <footer className="it-footer">
            <div className="it-footer-main">
                <Container>
                    <section>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <div className="it-brand-wrapper">
                                    <a
                                        className=""
                                        href="http://pti.regione.sicilia.it/portal/page/portal/PIR_PORTALE"
                                        title="Vai alla Regione Sicilia portale"
                                    >
                                        <Icon icon={logo} />
                                        <div className="it-brand-text">
                                            <h2>
                                                Regione Sicilia
                                            </h2>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <section>
                        <Row>
                            <Col
                                className="pb-2"
                                lg={6}
                                md={9}
                            >
                                <h4>
                                    <a>
                                        Informazioni
                                    </a>
                                </h4>
                                <Row>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                    >
                                        <p>
                                            <strong>
                                                CEFPAS - Cittadella Sant'Elia
                                            </strong>
                                            <br />
                                            Via Giuseppe Mulè. 1
                                            <br />
                                            93100 Caltanissetta
                                            <br />
                                            Tel +39 0934 505232
                                        </p>
                                    </Col>
                                    <Col
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                    >
                                        <p>
                                            <strong>
                                                CEFPAS - Sede di Palermo
                                            </strong>
                                            <br />
                                            Via Mario Vaccaro. 5
                                            <br />
                                            (presso DASOE)
                                            <br />
                                            90145 Palermo
                                            <br />
                                            Tel +39 091 7079361
                                            <br />
                                            Fax +39 091 7079245
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </div>
            <div className="it-footer-small-prints clearfix">
                <Container>
                    <h3 className="sr-only">
                        Sezione Link Utili
                    </h3>
                    <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                        <li className="list-inline-item">
                            <a
                                onClick={() => setOpenDialogCookie(!openDialogCookie)}
                                title="Cookie policy"
                                style={{ color: "white", cursor: "pointer", }}
                            >
                                Cookies Policy
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                onClick={() => setOpenDialogPrivacyPolicy(!openDialogPrivacyPolicy)}
                                title="Privacy policy"
                                style={{ color: "white", cursor: "pointer", }}
                            >
                                Privacy policy
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                onClick={() => setOpenDialogTerminiECondizioni(!openDialogTerminiECondizioni)}
                                title="Termini e Condizioni"
                                style={{ color: "white", cursor: "pointer", }}
                            >
                                Termini e Condizioni
                            </a>
                        </li>
                    </ul>
                </Container>
                {openDialogCookie && (
                    <CookiePolicyModal
                        openDialog={openDialogCookie}
                        toggleDialog={(value) => setOpenDialogCookie(value)}
                    />
                )}
                {openDialogPrivacyPolicy && (
                    <PrivacyPolicyModal
                        openDialog={openDialogPrivacyPolicy}
                        toggleDialog={(value) => setOpenDialogPrivacyPolicy(value)}
                    />
                )}
                {openDialogTerminiECondizioni && (
                    <TerminiECondizioniModal
                        openDialog={openDialogTerminiECondizioni}
                        toggleDialog={(value) => setOpenDialogTerminiECondizioni(value)}
                    />
                )}
            </div>
            <CookiesBanner
                setOpenDialogCookie={setOpenDialogCookie}
                openDialogCookie={openDialogCookie}
            />
        </footer>
    )
}

export default CustomFooter;