import * as React from "react";
import PropTypes from "prop-types";

function Body(props) {
  const { content } = props;

  return <div>{content}</div>;
}

Body.propTypes = {
  content: PropTypes.element.isRequired,
  auth: PropTypes.bool.isRequired,
};

export default React.memo(Body);
