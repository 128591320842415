import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { useState, useEffect } from 'react';

export const TimeoutModal = ({
    openDialog,
    toggleDialog,
    seconds
}) => {

    const [counter, setCounter] = useState(seconds);

  
    useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <Modal
            isOpen={openDialog}
            toggle={() => {
                toggleDialog(!openDialog)
            }}
            scrollable
            labelledBy='timeout'
            size='md'
        >
            <ModalHeader id='timeout'>Sessione in scadenza</ModalHeader>
            <ModalBody>
                <p>
                    La sessione scadrà tra {counter} secondi.
                </p>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
                <Button
                    color='primary'
                    onClick={() => {
                        toggleDialog(false);
                        localStorage.setItem('lastActvity', new Date());
                        localStorage.setItem('timeoutModal', new Date());
                    }}
                    size='md'
                >
                    Estendi sessione
                </Button>
            </ModalFooter>
        </Modal>)
}