import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';

export const TerminiECondizioniModal = ({
    openDialog,
    toggleDialog
}) => {
    return (
        <Modal
            isOpen={openDialog}
            toggle={() => {
                toggleDialog(!openDialog)
            }}
            scrollable
            labelledBy='terminiECondizioni'
            size='lg'
        >
            <ModalHeader id='terminiECondizioni'>Termini e Condizioni</ModalHeader>
            <ModalBody>
                <h4>Premessa</h4>
                <p>
                <p>
                    Il CEFPAS, insieme alla Regione Sicilia, ha come obiettivo la trasformazione 
                    digitale della Sanità verso una risposta sempre più adeguata alla domanda di salute
                    dei cittadini con l’offerta di servizi sanitari.
                </p>
                <br></br>
                <p>
                    Nella presente pagina sono riportati i Termini e le Condizioni relative all’utilizzo
                    dei Servizi digitali offerti dal presente Sito WEB.
                </p>
                <br></br>
                <p>
                    Il presente documento potrà essere modificato in qualsiasi momento e senza 
                    preavviso. L’utente è pertanto invitato a prendere regolarmente visione dell’ultima
                    versione aggiornata, disponibile e permanentemente accessibile da qualunque pagina 
                    dell’Applicazione, cliccando sul collegamento “Termini e Condizioni”.
                </p>
                <br></br>
                <p>
                    La visualizzazione, il download e qualunque utilizzo delle informazioni
                    del presente Sito WEB, 
                </p>
                </p>
                <h4>Utilizzo e download</h4>
                <p>
                    CEFPAS e Regione Siciliana si prefiggono di assicurare la continuità del servizio
                    e di ridurre al minimo gli eventuali problemi di natura tecnica. Tuttavia non è
                    escluso che il servizio possa essere soggetto ad interruzioni o malfunzionamenti
                    dovuti a cause esterne. 
                </p>
                <h4>Accesso a siti esterni collegati</h4>
                <p>
                <p>
                    I collegamenti a siti esterni, indicati nel presente sito, sono forniti come semplice
                    servizio agli utenti, con esclusione di ogni responsabilità sulla correttezza e sulla
                    completezza dell’insieme dei collegamenti indicati. 
                </p>
                <br></br>
                <p>
                    L’indicazione dei collegamenti non implica alcun tipo di approvazione o condivisione di
                    responsabilità in relazione alla legittimità, alla completezza e alla correttezza delle
                    informazioni contenute nei siti indicati.
                </p>
                <br></br>
                <p>
                    In tale prospettiva è opportuno leggere attentamente i termini e le condizioni che
                    disciplinano la navigazione su tali siti e/o portali collegati.
                </p>
                </p>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
                <Button
                    color='primary'
                    onClick={() => {
                        toggleDialog(!openDialog)
                    }}
                    size='md'
                >
                    Indietro
                </Button>
            </ModalFooter>
        </Modal>)
}