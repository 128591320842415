import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { useEffect, useState } from 'react';

export const PrivacyPolicyModal = ({
    openDialog,
    toggleDialog
}) => {

    return (
        <Modal
            isOpen={openDialog}
            toggle={() => toggleDialog(!openDialog)}
            scrollable
            labelledBy='privacyPolicy'
            size='lg'
        >
            <ModalHeader id='privacyPolicy'>Privacy policy</ModalHeader>
            <ModalBody>
                <h4>Perché questo avviso</h4>
                <p>
                    In questa pagina si descrivono le modalità di gestione del sito in riferimento
                    al trattamento dei dati personali degli utenti che lo consultano. Il Sito WEB tratta
                    tutti i dati personali degli utenti/visitatori, nel pieno rispetto di quanto previsto
                    dalla normativa europea e nazionale in materia di privacy e, in particolare, del
                    GDPR – Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio, del 27
                    aprile 2016, relativo alla protezione delle persone fisiche con riguardo al
                    trattamento dei dati personali, della Direttiva (UE) 2016/680 del Parlamento
                    europeo e del Consiglio, del 27 aprile 2016, relativa alla protezione delle
                    persone fisiche con riguardo al trattamento dei dati personali da parte delle
                    autorità competenti a fini di prevenzione, indagine, accertamento e perseguimento di reati o esecuzione di sanzioni penali, nonché alla libera circolazione di tali dati e del D. Lgs. 196/2003 e successive modifiche e integrazioni, in applicazione di quanto disposto dal D. Lgs. 10 agosto 2018, n. 101.
                </p>
                <br></br>
                <p>
                    La presente informativa è resa ai sensi degli artt. 13 e 14 del citato GDPR a tutti
                    coloro che si connettono al sito. L’informativa è resa solo per il sito e non anche
                    per altri siti web eventualmente consultati dall’utente tramite link esterno.
                </p>
                <h4>Definizioni</h4>
                <p>
                    Per “dato personale” (ex art. 4 numero 1 del Regolamento UE 2016/679) s’intende qualsiasi
                    informazione riguardante una persona fisica identificata o identificabile («interessato»);
                    si considera identificabile la persona fisica che può essere identificata, direttamente
                    o indirettamente, con particolare riferimento a un identificativo come il nome, un numero
                    di identificazione, dati relativi all’ubicazione, un identificativo online o a uno o più elementi caratteristici della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale.
                </p>
                <br></br>
                <p>
                    Per “categorie particolari di dati” (ex art. 9 numero 1 del Regolamento UE 2016/679) s’intendono
                    quei dati personali che rivelano l’origine razziale o etnica, le opinioni politiche, le convinzioni
                    religiose o filosofiche, o l’appartenenza sindacale, nonché dati genetici, dati biometrici
                    intesi a identificare in modo univoco una persona fisica, dati relativi alla salute o alla
                    vita sessuale o all’orientamento sessuale della persona.
                </p>
                <br></br>
                <p>
                    È possibile bloccare i Cookies tramite la funzione presente nelle impostazioni del Vostro 
                    browser, che permetterà di rifiutare l’impostazione di tutti o di alcuni Cookies. 
                    Tuttavia, se vorrete bloccare tutti i Cookies (inclusi anche quelli strettamente necessari), 
                    potreste non riuscire poi ad accedere a tutti o ad alcuni contenuti presenti sul Sito.
                {' '}
                </p>
                <br></br>
                <p>
                    Per “trattamento” (ex art. 4 numero 2 del Regolamento UE 2016/679) s’intende qualsiasi
                    operazione o insieme di operazioni, compiute con o senza l’ausilio di processi automatizzati
                    e applicate a dati personali o insiemi di dati personali, come la raccolta, la registrazione
                    l’organizzazione, la strutturazione, la conservazione, l’adattamento o la modifica,
                    l’estrazione, la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione
                    o qualsiasi altra forma di messa a disposizione, il raffronto o l’interconnessione, la
                    limitazione, la cancellazione o la distruzione.
                </p>
                <br></br>
                <p>
                    Per “Termini e Condizioni” o, più semplicemente “Condizioni” si intende indicare i Termini
                    e Condizioni di utilizzo del presente sito WEB che l’Utente accetta proseguendo la navigazione.
                    Nel caso in cui l’utente non sia d’accordo con tali condizioni e/o non intenda accettare
                    le medesime, non dovrà continuare la navigazione sul sito web.
                </p>
                <p>
                    Il Titolare si riserva il diritto di modificare, in tutto o in parte, il sito web e/o le
                    condizioni di seguito riportate. Pertanto, considerata detta ampia facoltà di modifica
                    senza necessità di alcun preavviso, si invitano gli utenti del sito web a prendere visione
                    delle presenti condizioni al fine di verificare che non siano intervenute modifiche.
                </p>
                <h4>Titolare del trattamento e data protection officer</h4>
                <p>
                    Il Titolare del trattamento dei dati è Regione Siciliana – xxxxxx con sede legale in città 
                    (CAP), indirizzo, P. IVA Partita IVA e-mail: eMial.
                </p>
                <br></br>
                <p>
                    Il Responsabile della Protezione dei Dati (RPD – DPO) è l’Avv. Nome Cognome.
                </p>
                <h4>Tipi di dati trattati</h4>
                <h5>(1) Dati di navigazione</h5>
                <p>
                    I sistemi informatici e le procedure software preposte al funzionamento di questo
                    Sito WEB acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la
                    cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.
                {' '}
                </p>
                <br></br>
                <p>
                    Si tratta di informazioni che non sono raccolte per essere associate a interessati
                    identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed
                    associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
                </p>
                <br></br>
                <p>
                    In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer
                    utilizzati dagli utenti che si connettono al sito, gli indirizzi URI (Uniform Resource Identifier)
                    delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la
                    richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante
                    lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi
                    al sistema operativo e all’ambiente informatico dell’utente.
                </p>
                <p>
                    Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime
                    sull’uso del sito e per controllarne il corretto funzionamento. I dati, in ogni caso,
                    come sopra già specificato, potrebbero essere utilizzati per l’accertamento di responsabilità
                    in caso di ipotetici reati informatici ai danni del sito.
                </p>
                <h5>(2) Dati forniti volontariamente dall’utente, inclusi i dati trattati in ragione
                 della creazione e del conseguente utilizzo di un profilo personale dell’Utente.</h5>
                <p>
                    L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi
                    indicati su questo sito comporta la successiva acquisizione dell'indirizzo del
                    mittente, necessario per rispondere alle richieste, nonché degli eventuali altri
                    dati personali inseriti nella missiva.
                {' '}
                </p>
                <br></br>
                <p>
                    L’Utente può scegliere di creare un proprio profilo sul presente sito web, conferendo
                    al Titolare i dati personali identificativi a ciò necessari. Alla creazione di un
                    profilo segue la registrazione di uno User Name che, corredato dalla relativa password,
                    consente all’Utente di accedere all’area personale del sito web. I dati trattati in
                    ragione della creazione e del conseguente utilizzo di un profilo personale dell’Utente
                    sono:
                </p>
                <br></br>
                <p>
                    DATI ANAGRAFICI: Nome - Cognome - Data di nascita – Email – Codice fiscale – Cellulare; 
                </p>
                <br></br>
                <p>
                    DATI DI ACCESSO: Nome Utente - Password - Conferma password; 
                </p>
                <br></br>
                <p>
                    DATI ISTITUZIONALI: Ente - Comune – Provincia - Regione; 
                </p>
                <br></br>
                <p>
                    PROFILO ISITUZIONALE: Ruolo – Settore/Ufficio – Altro Ruolo - Altro Settore/Ufficio.  
                </p>
                <h5>(3) Cookies</h5>
                <p>
                    Conformemente al provvedimento del Garante per la protezione dei dati personali dell’8
                    maggio 2014 e modifiche successive e integrazioni, il Titolare del sito comunica che
                    questo sito può utilizzare cookies tecnici propri per il corretto funzionamento delle
                    pagine web e per il miglioramento dei servizi come in dettaglio specificato di seguito.
                {' '}
                </p>
                <br></br>
                <p>
                    I cookies sono costituiti da porzioni di codice installate all’interno del browser che
                    assistono il Titolare nell’erogazione del servizio in base alle finalità descritte. Alcune
                    delle finalità di installazione dei cookies potrebbero, inoltre, necessitare del
                    consenso dell’utente. Per avere maggiori informazioni sulla tipologia di cookies utilizzati,
                    le finalità e le modalità di disabilitazione è possibile consultare la sezione specifica
                    cookies-policy.
                </p>
                <br></br>
                <p>
                    Alcuni dei servizi elencati di seguito potrebbero non richiedere il consenso dell'utente o
                    potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto –
                    senza l’ausilio di terzi.
                    Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi,
                    questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare –
                    compiere attività di tracciamento dell’utente. Per informazioni dettagliate in merito, si
                    consiglia di consultare le privacy policy dei servizi elencati.
 
                </p>
                <h4>Finalità del trattamento</h4>
                <h5>(1) Dati di navigazione, Cookie</h5>
                <p>
                    Le finalità della raccolta e della conservazione dei dati, attraverso il Sito stesso, è
                    effettuata per finalità connesse al miglioramento dell’esperienza di navigazione, per rendere
                    più facile ed intuitivo il sito stesso, per migliorare la tipologia e la qualità dei
                    contenuti e ottimizzare le performance operative. Il sistema informativo e le procedure
                    software preposte al funzionamento di questo Sito WEB acquisiscono, nel corso del loro
                    normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei
                    protocolli di comunicazione di internet. Si tratta di informazioni che non sono raccolte
                    per essere associate a interessati identificati, ma che per loro stessa natura
                    potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi,
                    permettere di identificare gli utenti. In questa categoria di dati rientrano gli
                    indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono
                    al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
                    richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la
                    richiesta al server, la dimensione del file ottenuto in risposta, il codice
                    numerico indicante lo stato della risposta data dal server (buon fine, errore,
                    ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico
                    dell’utente.
                {' '}
                </p>
                <br></br>
                <p>
                    Inoltre, per ragioni di sicurezza e per assicurare la piena disponibilità del servizio
                    a tutti gli utilizzatori, il Titolare del Sito può utilizzare programmi software di
                    controllo del traffico sul sito, al fine di identificare tentativi non autorizzati di
                    inserimento o di variazione delle informazioni o qualsivoglia altro tentativo di intrusione
                    o di danneggiamento. 
                </p>
                <br></br>
                <p>
                    Tali informazioni potrebbero essere utilizzate per l’accertamento di responsabilità in caso
                    di ipotetici reati informatici ai danni della Regione. Secondo la disciplina in tema di
                    protezione dei dati personali, tale trattamento sarà improntato ai principi di correttezza,
                    liceità e trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.
                </p>
                <h5>(2) Dati forniti volontariamente dall’utente</h5>
                <p>
                    I dati forniti volontariamente dall’Utente sono trattati per le finalità rispetto alle quali i
                    dati stessi sono stati forniti, ossia per erogare il servizio richiesto, dare riscontro alle
                    istanze e/o richieste dell’Utente, attivare l’area personale dell’utente e consentirne il
                    regolare utilizzo.
                {' '}
                </p>
                <br></br>
                <p>
                    L’Utente può scegliere di creare un proprio profilo sul presente sito web, conferendo
                    al Titolare i dati personali identificativi a ciò necessari. Alla creazione di un
                    profilo segue la registrazione di uno User Name che, corredato dalla relativa password,
                    consente all’Utente di accedere all’area personale del sito web. I dati trattati in
                    ragione della creazione e del conseguente utilizzo di un profilo personale dell’Utente
                    sono:
                </p>
                <h4>Base giuridica e liceità del trattamento</h4>
                <p>
                    Il trattamento dei dati di navigazione, Cookie non richiede il consenso: si tratta infatti
                    di operazioni di trattamento dei dati personali che si rendono necessarie per consentire la
                    consultazione del sito e/o funzionali al legittimo interesse del Titolare.
                </p>
                <br></br>
                <p>
                    Il trattamento dei dati personali eventualmente forniti dagli utenti trova fondamento nel
                    consenso dell’Utente stesso.
                </p>
                <h4>Come controllare l’installazione di cookies</h4>
                <p>
                    In aggiunta a quanto indicato in questo documento, l'utente può gestire le preferenze relative
                    ai cookies direttamente all'interno del proprio browser ed impedire – ad esempio – che terze
                    parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare
                    i cookies installati in passato, incluso i cookies in cui venga eventualmente salvato il
                    consenso all'installazione di cookies da parte di questo sito. L’utente può trovare
                    informazioni su come gestire i cookies nel suo browser agli indirizzi web dei principali
                    programmi browser: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Windows
                    Explorer.
                </p>
                <br></br>
                <p>
                    In caso di servizi erogati da terze parti, l’utente può inoltre esercitare il proprio diritto
                    ad opporsi al tracciamento, informandosi tramite la privacy policy della terza parte, tramite
                    il link di opt out se esplicitamente fornito o contattando direttamente la stessa.
                </p>
                <br></br>
                <p>
                    Fermo restando quanto precede, il Titolare informa che l’utente può avvalersi di appositi siti
                    web attraverso i quali è possibile gestire le preferenze di tracciamento della maggior parte
                    degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli utenti di utilizzare tali
                    risorse in aggiunta alle informazioni fornite dal presente documento.
                {' '}
                </p>
                <br></br>
                <p>
                    Dal momento che l’installazione di cookies e di altri sistemi di tracciamento operata da terze
                    parti tramite i servizi utilizzati all’interno di questo sito, non può essere tecnicamente
                    controllata dal Titolare, ogni riferimento specifico a cookies e sistemi di tracciamento
                    installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete,
                    s’invita a consultare la privacy policy degli eventuali servizi terzi.
                </p>
                <br></br>
                <p>
                    Vista l’oggettiva complessità legata all’identificazione delle tecnologie basate sui cookies
                    e alla loro integrazione molto stretta con il funzionamento del web, l’utente è invitato a
                    contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo
                    all'utilizzo dei cookies stessi e ad eventuali utilizzi degli stessi – ad esempio ad opera
                    di terzi – effettuati tramite questo sito.
                </p>
                <h4>Luogo del trattamento</h4>
                <p>
                    Il Titolare si riserva la facoltà di individuare soggetti terzi per il supporto tecnologico ed
                    operativo che saranno nominati Responsabili del trattamento ai sensi della vigente normativa.
                    Nessun dato derivante dal servizio web viene comunicato o diffuso, salvo i casi espressamente
                    previsti dalla legge. I dati personali eventualmente forniti volontariamente dagli utenti
                    sono utilizzati al solo fine di eseguire il servizio o la specifica prestazione.
                    Occasionalmente, potrà essere consentito a terzi fornitori l’accesso temporaneo alle
                    memorie del sito per esclusivi motivi di assistenza tecnica, nel rispetto delle
                    prescrizioni di legge e sotto la sorveglianza del Titolare. I dati non sono comunicati
                    all’estero.
                </p>
                <h4>Facoltatività del conferimento dei dati</h4>
                <p>
                    A parte quanto specificato per i dati di navigazione, l'utente è libero di fornire i dati
                    personali. Tuttavia, il loro mancato conferimento può comportare l'impossibilità di ottenere
                    eventuali comunicazioni legate a richieste effettuate.
                </p>
                <h4>Comunicazione dei dati</h4>
                <p>
                    I dati non saranno oggetto di comunicazione. L’ambito di diffusione dei dati sarà quello strettamente
                    limitato all’esecuzione dell’incarico da noi conferito ai soggetti sopra indicati. Dei dati forniti
                    potranno venire a conoscenza i Responsabili del trattamento, nonché i soggetti autorizzati al
                    trattamento che operano sotto la diretta autorità del Titolare o del Responsabile. 
                </p>
                <p>
                    In caso di necessità, per attività legate alla manutenzione della parte tecnologica del sito, i dati
                    connessi al servizio possono essere trattati dal soggetto incaricato della manutenzione del sito web,
                    il quale è, a tal fine, nominato Responsabile del trattamento ai sensi dell’articolo 4, n.8 e dell’art.
                    28 del GDPR - Reg. UE n. 679/2016. 
                </p>
                <h4>Modalità e durata del trattamento dei dati</h4>
                <p>
                    I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire
                    gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la
                    perdita dei dati, usi illeciti o non corretti e accessi non autorizzati. 
                </p>
                <h4>Diritti degli interessati</h4>
                <p>
                    Ai sensi degli articoli 15 e seguenti del Reg. UE 679/2016 (GDPR), l’utente ha il diritto di
                    chiedere in qualunque momento, l’accesso ai suoi dati personali, la rettifica o la cancellazione
                    degli stessi, la limitazione del trattamento nei casi previsti dall’art. 18 del medesimo Regolamento,
                    ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati
                    che lo riguardano, nei casi previsti dal successivo art. 20. 
                </p>
                <br></br>
                <p>
                    In ogni momento, l’utente può revocare ex art. 7 del Regolamento il consenso prestato (laddove il
                    consenso sia stato richiesto), proporre reclamo all’autorità di controllo competente ex art. 77
                    dello stesso, qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore. 
                </p>
                <br></br>
                <p>
                    L’utente può, inoltre, formulare una richiesta di opposizione al trattamento dei suoi dati personali ex
                    art. 21 del Regolamento nella quale dare evidenza delle ragioni che giustifichino l’opposizione: il
                    Titolare si riserva di valutare l’istanza, che non verrebbe accettata in caso di esistenza di motivi
                    legittimi cogenti per procedere al trattamento che prevalgano sugli interessi, diritti e libertà dell’utente. 
                {' '}
                </p>
                <br></br>
                <p>
                    L’interessato potrà esercitare i diritti sopra menzionati scrivendo alla Regione Siciliana - xxxxx, indirizzo,
                    – CAP, Città, e-mail: email.
                </p>
                <h4>Aggiornamenti alla privacy policy</h4>
                <p>
                    Le notizie rese nella presente informativa possono essere soggette a revisione in seguito a: 
                </p>
                <ul>
                    <li>
                        modifiche della normativa di privacy, per gli aspetti qui di interesse;
                    </li>
                    <li>
                    	implementazioni tecnologiche del sito che impattino sulle attuali modalità di trattamento;
                    </li>
                    <li>
                    	variazioni organizzative nella struttura di privacy del Titolare che possano interessare l’utente.
                    </li>
                </ul>
                <p>
                    Gli utenti sono invitati a visitare periodicamente la Privacy Policy in modo da essere costantemente aggiornati
                    sulle caratteristiche del trattamento. 
                </p>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
                <Button
                    color='primary'
                    onClick={() => toggleDialog(!openDialog)}
                    size='md'
                >
                    Indietro
                </Button>
            </ModalFooter>
        </Modal>)
}