import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { useState } from 'react';

export const CookiePolicyModal = ({
    openDialog,
    toggleDialog
}) => {
    const [showDetailedText, setShowDetiledText] = useState(false);
    return (
        <Modal
            isOpen={openDialog}
            toggle={() => {
                toggleDialog(!openDialog)
            }}
            scrollable
            labelledBy='cookiePolicy'
            size='lg'
        >
            <ModalHeader id='cookiePolicy'>Cookie policy</ModalHeader>
            <ModalBody>
                {showDetailedText ?
                    (<>
                        <h4>1. Introduzione</h4>
                        <p>
                            In questa sezione del sito si descrivono le modalità di utilizzo dei cookie.
                            Si riportano alcune definizioni.
                        </p>
                        <br></br>
                        <p>
                            I <b>cookie</b> sono piccoli file di testo che i siti visitati dagli utenti inviano ai
                            dispositivi usati per la consultazione (computer, smartphone, tablet, smartTV, ecc.) per
                            essere memorizzati e poi ritrasmessi agli stessi siti in occasione della visita successiva.
                            I cookie sono utilizzati per far funzionare più efficientemente e migliorare la navigazione
                            on-line dell'utente e possono svolgere importanti e diverse funzioni, tra cui il monitoraggio
                            di sessioni, la memorizzazione di informazioni su specifiche configurazioni riguardanti gli
                            utenti che accedono al server, l’agevolazione nella fruizione dei contenuti online o tener
                            traccia delle informazioni utilizzate per la compilazione di un modulo informatico.
                            Alcune tipologie di cookie possono essere utilizzate per personalizzare la propria
                            esperienza sul sito e richiedono il consenso preliminare esplicito da parte dell’utente.
                        </p>
                        <br></br>
                        <p>
                            Queste informazioni possono rimanere nel sistema per la durata di una sessione c.d.
                            <b>cookie di sessione</b> e permettere di utilizzare temporaneamente le informazioni necessarie
                            per la navigazione all’interno della Piattaforma, e vengono eliminati alla chiusura del
                            browser o essere memorizzati anche al termine della sessione per un certo periodo di tempo
                            c.d. <b>cookie persistenti</b> e possono essere usati per miotivi tecnici per es. per evitare di
                            inserire le credenziali ad ogni accesso o per profilazione per es. per registrare le informazioni
                            sulle abitudini dell’utente.
                        </p>
                        <br></br>
                        <p>
                            I cooke possono essere classificati anche in base all’origine come <b>cookie di prima parte</b>, se i
                            cookie sono installati dal sito che l’utente sta visitando e trattati direttamente dal gestore o
                            dello stesso sito. I dati raccolti saranno trattati in modo proprietario sui server del gestore
                            del sito web, o <b>cookie di terza parte</b> se i cookie sono installati dal il sito che l’utente sta
                            visitando ma gestiti da un soggetto diverso dal gestore dello stesso sito. I dati raccolti saranno
                            trasmessi e trattati in una piattaforma e/o servizio esterno.
                        </p>
                        <br></br>
                        <p>
                            Se consentono di identificare una persona fisica mediante la correlazione di altre informazioni
                            per esempio il dispositivo univoco utilizzato per l’accesso e la navigazione on-line i
                            <b>cookie sono considerati dati personali</b> come gli indirizzi IP e altri identificatori ai sensi del considerando
                            30 del GDPR e pertanto richiedono il consenso esplicito preliminare dell’utente del sito e devono
                            essere trattati dal Titolare nel pieno rispetto dei principi di licelità, necessità, minimizzazione,
                            limitazione della conservazione, mediante l'adozione di misure tecniche e organizzative adeguate al
                            livello di rischio dei trattamenti, per un arco di tempo non superiore al conseguimento delle
                            finalità di cui sopra e comunque non oltre il periodo previsto dalla normativa applicabile.

                        </p>
                        <br></br>

                        <h4>2. Descrizione dei cookie utilizzati nel sito web</h4>
                        <p>
                            Il Sito WEB utilizza solo i cookies Tecnici necessari alla gestione dell’autenticazione
                            dell’utente e in generale al funzionamento e alla fruizione del servizio web, al solo fine
                            di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica,
                            o nella misura strettamente necessaria al fornitore di un servizio della società
                            dell’informazione esplicitamente richiesto dal contraente o dall’utente a erogare tale servizio",
                            ai sensi dell’art. 122, comma 1 del Codice Privacy.
                        </p>
                        <br></br>
                        <p>
                            In particolare si riporta una descrizione dettagliata dei cookie utilizzati.

                        </p>
                        <br></br>

                        <h4>Cookies necessari o Cookie non opzionali:</h4>
                        <h5>Sempre attivi</h5>
                        <p>
                            I cookie strettamente necessari permettono l'utilizzo di determinate funzioni,
                            senza le quali non è possibile utilizzare il nostro sito web come previsto.
                            Questi cookie  sono cookie di prima parte e  sono esclusivamente utilizzati dal
                            gestore del sito e non sono riconducibili all’utente.
                        </p>
                        <br></br>
                        <p>
                            In generale tra i cookie non opzionali vengono ricompresi i cookie tecnici ed i
                            cookie analitici di prima e di terza parte che non consentono l’identificazione
                            dell’utente.
                        </p>
                        <br></br>
                        <p>
                            I cookie tecnici consentono di effettuare correttamente la navigazione o fornire
                            un servizio richiesto e non sono utilizzati per scopi ulteriori. Senza il ricorso
                            a tali cookie, alcune operazioni non potrebbero essere compiute o sarebbero più
                            complesse e/o meno sicure. I cookie tecnici consentono di effettuare e mantenere
                            l´identificazione dell´utente nell´ambito della sessione risultando indispensabili.
                            Pertanto, non viene richiesto il consenso per il loro utilizzo.
                        </p>
                        <br></br>
                        <p>
                            In questa categoria di cookie non opzionali sono ricompresi i cookie analitici di
                            prima e di terza parte utilizzati soltanto per raccogliere informazioni, in forma
                            aggregata, sul numero degli utenti e su come questi visitano il sito stesso; inoltre,
                            consentono di elaborare statistiche generali sul servizio. L’utilizzo di tali cookie
                            non prevede la richiesta di consenso in quanto sono stati adottati strumenti che non
                            consentono l’identificazione dell’utente.

                        </p>
                        <br></br>

                        <h4>Cookies Tecnici:</h4>
                        <h5>Sempre attivi</h5>
                        <p>
                            Questo tipo di Cookies permette di gestire l’autenticazione dell’utente loggato al
                            Sito WEB, la fruizione dei servizi, garantendone la sicurezza delle operazioni.
                        </p>
                        <br></br>

                        <h4>Cookies Analitici o Statistici di Prima Parte e di Terze Parti:</h4>
                        <p>
                            Non sono presenti cookie analitici di prima e di terze parti e in generale nessun
                            dato personale degli utenti viene in proposito acquisito.
                        </p>
                        <br></br>
                        <p>
                            Questo tipo di Cookies permette di raccogliere informazioni <b>statistiche</b> sugli
                            utenti che accedono al sito e alle pagine che visitano, in forma aggregata o in chiaro.

                        </p>
                        <br></br>

                        <h4>Cookies di Profilazione o Marketing di Prima Parte e di Terze Parti:</h4>
                        <p>
                            Non sono presenti cookie di profilazione di prima parte e di terze parti e in generale
                            nessun dato personale degli utenti viene in proposito acquisito.
                            Questo tipo di Cookies permette di tracciare la navigazione dell´utente e creare
                            profili basati sulle sue preferenze, ivi incluse abitudini e scelte. Grazie a tali
                            cookie possono essere trasmessi al terminale dell´utente messaggi pubblicitari, in
                            linea con le preferenze già manifestate dallo stesso utente nella navigazione online.
                            Possono essere utilizzati per ricondurre a soggetti determinati,identificati o
                            Identificabili, specifiche azioni o schemi comportamentali ricorrenti nell’uso delle
                            funzionalità offerte (pattern) al fine del raggruppamento dei diversi profili all’interno
                            di cluster omogenei di diversa ampiezza, in modo che sia possibile al titolare, tra
                            l’altro, anche modulare la fornitura del servizio in modo sempre più personalizzato al di
                            là di quanto strettamente necessario all’erogazione del servizio, nonché inviare messaggi
                            pubblicitari mirati, cioè in linea con le preferenze manifestate dall’utente nell’ambito
                            della navigazione in rete.
                        </p>
                        <br></br>

                        <h4>Cookies di Terze parti:</h4>
                        <p>
                            Non sono presenti.
                        </p>
                        <br></br>
                        <p>
                            Questo tipo di Cookies sono impostati da un sito web diverso da quello che l´utente sta
                            visitando. Su ogni sito possono essere presenti elementi che risiedono su server diversi
                            da quello del sito visitato, come per esempio nel caso di immagini, suoni, specifici link
                            a pagine web di altri domini. Tali cookie non sono indispensabili alla navigazione, pertanto
                            in qualunque momento può esserne disattivato l’utilizzo attraverso le apposite funzioni
                            del browser.
                        </p>
                        <br></br>

                        <h4>Cookies funzionali di Terze parti:</h4>
                        <p>
                            Non sono presenti.
                        </p>
                        <br></br>
                        <p>
                            I cookie funzionali consentono di analizzare l’utilizzo del sito da parte dell’utente, salvare
                            le preferenze dell’utente e possono fornire una migliore esperienza di navigazione sul sito.
                            L’utilizzo di tali cookie di terze parti prevede la richiesta di consenso in quanto non sono
                            adottati strumenti che riducono il potere identificativo dei cookie.
                        </p>
                        <br></br>

                        <h4>3. Conclusioni</h4>
                        <p>
                            In generale, il trattamento avente ad oggetto i citati cookies risulta conforme a quanto previsto
                            dalle Linee guida adottate dal Garante per la protezione dei dati personali con il Provvedimento
                            recante le “Linee guida cookie e altri strumenti di tracciamento”, n.231 del 10 giugno 2021,
                            pubblicatoto sulla GU n.163 del 9 luglio 2021.
                        </p>
                        <br></br>
                        <p>
                            Con la navigazione nel sito, l’utente accetta espressamente la politica di utilizzo dei Cookies.
                        </p>
                        <br></br>
                        <p>
                            È possibile bloccare i Cookies tramite la funzione presente nelle impostazioni del browser
                            dell’utente, che permetterà di rifiutare l’impostazione di tutti o di alcuni Cookies. Tuttavia,
                            se l’utente intende bloccare tutti i Cookies (inclusi anche quelli strettamente necessari),
                            potrebbe non riuscire poi ad accedere a tutti o ad alcuni contenuti presenti sul Sito.
                        </p>
                        <br></br>

                        <h4>Ulteriori Informazioni:</h4>
                        <p>
                            Ulteriori informazioni in ordine al trattamento dei dati personali e ai diritti riconosciuti agli
                            interessati possono essere reperiti nella Privacy Policy e sul sito web del Garante per la protezione
                            dei dati personali all’indirizzo <a href="https://www.garanteprivacy.it">www.garanteprivacy.it</a> .
                        </p>
                        <br></br>
                    </>) :
                    (<>
                        <p>
                            Il Sito WEB utilizza solo Cookies Tecnici necessari per la gestione
                            dell’autenticazione dell’utente. Navigando il sito, si accetta espressamente
                            la nostra politica di utilizzo dei Cookies.
                        </p>
                        <br></br>
                        <h4>Cookies necessari:</h4>
                        <h5>Sempre attivi</h5>
                        <p>
                            I cookie strettamente necessari permettono l'utilizzo di determinate funzioni,
                            senza le quali non è possibile utilizzare il nostro sito web come previsto.
                            Questi cookie (First Party Cookies) sono esclusivamente utilizzati da noi
                            e non sono riconducibili a te.
                        </p>
                        <br></br>
                        <h4>Cookies tecnici:</h4>
                        <h5>Sempre attivi</h5>
                        <p>
                            Questo tipo di Cookies permette di gestire l’autenticazione dell’utente loggato
                            al Sito WEB garantendone la sicurezza delle operazioni.
                        </p>
                        <br></br>
                        <h4>Cookies Analitici o Statistici di Prima Parte e di Terze Parti:</h4>
                        <p>
                            Non sono presenti cookie analitici di prima e di terze parti e in generale nessun
                            dato personale degli utenti viene in proposito acquisito
                        </p>
                        <br></br>
                        <h4>Cookies di Profilazione o Marketing di Prima Parte e di Terze Parti:</h4>
                        <p>
                            Non sono presenti cookie di profilazione di prima parte e di terze parti e in
                            generale nessun dato personale degli utenti viene in proposito acquisito.
                        </p>
                        <br></br>
                        <p>
                            È possibile bloccare i Cookies tramite la funzione presente nelle impostazioni del Vostro
                            browser, che permetterà di rifiutare l’impostazione di tutti o di alcuni Cookies.
                            Tuttavia, se vorrete bloccare tutti i Cookies (inclusi anche quelli strettamente necessari),
                            potreste non riuscire poi ad accedere a tutti o ad alcuni contenuti presenti sul Sito.
                            {' '}
                        </p>
                    </>)}
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
                {!showDetailedText && <Button
                    color='primary'
                    onClick={() => {
                        toggleDialog(!openDialog)
                    }}
                    size='md'
                >
                    Indietro
                </Button>}
                <Button
                    color='primary'
                    onClick={() => {
                        setShowDetiledText(!showDetailedText);
                    }}
                    size='md'
                >
                    {showDetailedText ? "Indietro" : "Dettaglio"}
                </Button>
            </ModalFooter>
        </Modal>)
}